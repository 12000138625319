<script>
import User from "@/components/User.vue";
import UserAvatar from "@/components/UserAvatar.vue";

export default {
    name: "Users",
    components: {
        UserAvatar,
        User
    },

    props: {
        users: {
            type: Array,
            required: false,
            default: () => []
        },
        disable: {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>

<template>
    <div v-if="users.length > 0">
        <template v-if="users.length > 1">
            <div class="avatars">
                <UserAvatar v-for="user in users" :key="user.id" :img="user.photo" :alt="user.name"></UserAvatar>
            </div>
        </template>
        <template v-else>
            <User :item="users[0]"></User>
        </template>
    </div>
</template>

<style lang="scss">
.avatars {
    display: inline-block;
    margin-left: 18px;

    .avatar {
        margin-left: -18px;
        position: relative;

        @for $i from 1 through 20 {
            &:nth-child(#{$i}) {
                z-index: 20 - $i;

                &:hover {
                    z-index: 100;
                    box-shadow: 0 0 10px rgba(255, 255, 255, 1);
                }
            }
        }
    }
}
</style>
